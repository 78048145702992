<template lang="pug">
.card-2
  .box-alert
    .icon
      BaseCustomIcon(nameIcon="mdiCheckBold", :size="48")
    .content
      .title {{ title }}
      p(v-if="message") {{ message }}
</template>

<script>
export default {
  name: "cardAlert",
  props: {
    title: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.card-2 {
  @apply rounded-md py-8 pr-8 pl-12 w-full shadow-md relative overflow-hidden border-2 border-green-500 bg-green-50;
  .title {
    @apply text-green-800 font-bold text-lg;
  }
  p {
    @apply py-2 text-sm font-medium text-green-600;
  }

  .box-alert {
    @apply flex items-center;
  }

  .icon {
    @apply bg-green-500 text-white rounded-full p-2 mr-4;
  }
}
</style>
